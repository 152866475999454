import { Box, Button, Form, FormField, Layer, TextArea, TextInput } from 'grommet';
import React from 'react';

function submitSupportForm(value) {
    console.log(`Support form values: ${JSON.stringify(value, null, 2)}`);
    fetch('https://vaicai.com/api/support/contact/', {
            method: "POST",
            body: JSON.stringify(value, null, 2),
            headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then(
                (response) => (response.json())
            ).then((response)=> {
                if (response.status === 'success') {
                alert("Message Sent.");
                window.location.reload(false);
            } else if(response.status === 'fail') {
                alert("Message failed to send.")
            }
        })
}

function SupportForm() {
    const [value, setValue] = React.useState({});
    return (
        <div className="form">
            <p className="form">
            <h1>Need Help With Something?</h1><br></br>
            Fill out the form below and we'll get back to you as soon as possible! <br></br>
      <Form
        className="form"
        value={value}
        onChange={nextValue => setValue(nextValue)}
        onReset={() => setValue({})}
        onSubmit={({ value }) => {submitSupportForm(value);}}
      >
        <FormField className="form-field" name="name" htmlFor="text-input-id" label="Name">
        <Box direction="row" border={{ color: 'grey', size: 'small' }} round="small">
          <TextInput
           id="name-text-input-id"
            name="name"
            className="form-field"
            size="small"
            width="medium"
            border={{ color: 'grey', size: 'large' }} 
            round
            placeholder="What's Your Name?"/>
            </Box>
        </FormField>
        <FormField  className="form-field" name="email" htmlFor="text-input-id" label="Email Address">
        <Box direction="row" border={{ color: 'grey', size: 'small' }} round="small">
          <TextInput 
          id="email-text-input-id"
          name="email"
          className="form-field"
          size="small"
          width="medium"
          border={{ color: 'grey', size: 'large' }} 
          round
          placeholder="What's Your Email Address?" />
            </Box>
        </FormField>
        <FormField className="form-field" name="message" htmlFor="text-input-id" label="Issue">
        <Box border={{ color: 'grey', size: 'small' }} round="small">
            <TextArea
             id="issue-text-input-id"
            name="message" 
            className="form-field"
            border={{ color: 'grey', size: 'large' }} 
            size="small"
            width="xlarge"
            round
            placeholder="What do you need help with?"/>
            </Box>
        </FormField>
        <Box direction="row" gap="medium" align="center">
          <Button type="submit" primary label="Submit" color="#5F22D9" />
        </Box>
      </Form>
      </p>
        </div>
    );
  }

function SupportLayer() {
    const [show, setShow] = React.useState();
    return (
      <div className="header-item">
        <Button label="Support" onClick={() => setShow(true)}/>
        {show && (
          <Layer
            onEsc={() => setShow(false)}
            onClickOutside={() => setShow(false)}
          >
            <SupportForm/>
          </Layer>
        )}
      </div>
    );
  }

  export default SupportLayer;