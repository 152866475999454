import './App.css';
import React from "react";
import Landing from './components/Landing';
import { initializeApp } from "firebase/app";
import EnterPhone from './components/Login/EnterPhone';
import { ChakraProvider } from '@chakra-ui/react';


const firebaseConfig = {
  apiKey: "AIzaSyBOP7ypkIE9bBPfJw2uiacBpH2UlWB7tLE",
  authDomain: "vaicai-9e0ef.firebaseapp.com",
  databaseURL: "https://vaicai-9e0ef-default-rtdb.firebaseio.com",
  projectId: "vaicai-9e0ef",
  storageBucket: "vaicai-9e0ef.appspot.com",
  messagingSenderId: "437870072240",
  appVerificationDisabledForTesting: true,
  settings: {
      appVerificationDisabledForTesting: true,
  },
  appId: "1:437870072240:web:278e6eae72a28b1fdc0675",
  measurementId: "G-CCF5JSNB2P",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
function App() {
  return <ChakraProvider>

    <Landing landingPath="home"/>
    {/* <EnterPhone state="input" app={app} onSubmitOTP= {() => {}} recaptchaResponse={null}  onRecaptchaResponse={() => {}} onConfirmationResult={() => {console.log("wow")}}/> */}
    </ChakraProvider>
}

export default App;