import { extendTheme } from "@chakra-ui/react";

const customColors = {
    electricIndigo: "#5F22D9",
    persianIndigo: "#2C016D",
    mikadoYellow: "#F9C200",
    turquoise: "#51D6CA",
    chefchaouenBlue: "#3F8EFC",
    ultraPink: "#F361FB",
    platinum: "#E4E4E4",
    fireEngineRed: "#C82B28",
    yellowGradient: {
      500: "linear-gradient(198deg, #FFE500 16.14%, #FFC200 55.04%, #FF8A00 93.94%)",
    }
  };
  

const theme = extendTheme({
  fonts: {
    heading: "Outfit", 
    body: "Outfit", 
  },
  colors: customColors,
});

export default theme;
