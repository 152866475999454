import React from "react";
import {
    Input,Button,Text,
    VStack,
    FormControl, FormLabel, Box,
    HStack, Flex, MenuButton, Menu, MenuList,
    MenuItem, Spacer, Heading, Heading3
} from '@chakra-ui/react';
import CountryFlag from 'react-country-flag';
import config from '../../firebase/config';
import { initializeApp } from "firebase/app";
import { RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider, getAuth } from 'firebase/auth';
// import { initializeApp } from "https://www.gstatic.com/firebasejs/9.17.2/firebase-app.js";

// import { getAuth, onAuthStateChanged } from "https://www.gstatic.com/firebasejs/9.17.2/firebase-auth.js";
import EnterOTP from "./EnterOTP";
// import { onAuthStateChanged } from "https://www.gstatic.com/firebasejs/9.17.2/firebase-auth.js";
// import firebaseauthui from 'firebaseui';
// import "firebase/compat/auth";
// const firebase = require('firebase/app')
// const firebase_default = firebase.default
// Add the Firebase products that you want to use
// import "firebase/auth";
// import firebase from "firebase";
// import "firebase/firestore";
// import "firebase/auth";

// require('firebase/auth');
// import { useUserAuth } from "../context/UserAuthContext";z

// var firebaseui = require('firebaseui');
const firebaseConfig = {
    apiKey: "AIzaSyBOP7ypkIE9bBPfJw2uiacBpH2UlWB7tLE",
    authDomain: "vaicai-9e0ef.firebaseapp.com",
    databaseURL: "https://vaicai-9e0ef-default-rtdb.firebaseio.com",
    projectId: "vaicai-9e0ef",
    storageBucket: "vaicai-9e0ef.appspot.com",
    messagingSenderId: "437870072240",
    appVerificationDisabledForTesting: true,
    settings: {
        appVerificationDisabledForTesting: true,
    },
    appId: "1:437870072240:web:278e6eae72a28b1fdc0675",
    measurementId: "G-CCF5JSNB2P",
};
const app = initializeApp(firebaseConfig); //firebase.getApp(firebaseConfig);
// const auth = g
// const auth = compatApp.auth();
// const authUI = new firebaseui.auth.AuthUI(auth);



const countryCodes = [
    { name: 'United States', code: '+1', flagCode: 'US' },
    { name: 'Canada', code: '+1' , flagCode: 'CA' },
    { name: 'United Kingdom', code: '+44' , flagCode: 'GB' },
    { name: 'Australia', code: '+61', flagCode: 'AU' },
    { name: 'New Zealand', code: '+64' , flagCode: 'NZ' },
    { name: 'India', code: '+91', flagCode: 'IN' },
    { name: 'Brazil', code: '+55' , flagCode: 'BR' },
    { name: 'South Africa', code: '+27' , flagCode: 'ZA' },
    { name: 'Nigeria', code: '+234', flagCode: 'NG' },
    { name: 'Kenya', code: '+254' , flagCode: 'KE' },
    { name: 'Mexico', code: '+52', flagCode: 'MX' },
    { name: 'Germany', code: '+49', flagCode: 'DE' },
    { name: 'France', code: '+33' , flagCode: 'FR' },
    { name: 'Italy', code: '+39', flagCode: 'IT' },
    { name: 'Spain', code: '+34', flagCode: 'ES' },
    { name: 'China', code: '+86' , flagCode: 'CN' },
    { name: 'Japan', code: '+81' , flagCode: 'JP' },
    { name: 'Singapore', code: '+65', flagCode: 'SG' },
    { name: 'Pakistan', code: '+92' , flagCode: 'PK' },
    { name: "Trinidad and Tobago", code: "+1", flagCode: "TT"},
    { name: "Jamaica", code: "+1", flagCode: "JM"},
    { name: "Barbados", code: "+1", flagCode: "BB"},
    { name: "Bahamas", code: "+1", flagCode: "BS"},
    { name: "Grenada", code: "+1", flagCode: "GD"},
    { name: "Saint Lucia", code: "+1", flagCode: "LC"},
    { name: "Saint Vincent and the Grenadines", code: "+1", flagCode: "VC"},
    { name: "Antigua and Barbuda", code: "+1", flagCode: "AG"},
    { name: "Dominica", code: "+1", flagCode: "DM"},
    { name: "Saint Kitts and Nevis", code: "+1", flagCode: "KN"},
    { name: "Saint Martin", code: "+590", flagCode: "MF"},
    // ... and so on for other countries
];

class EnterPhone extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCountry: countryCodes[0],
            phoneNumber: '',
            isModalOpen: false,
            isPhoneNumberValid: true,
            // auth: props.auth,
            app: props.app,
            confirmationResult: props.confirmationResult,
            viewState: props.state,
            recaptchaReponse: props.recaptchaReponse,
            referralCode: props.referralCode
        };
        this.onConfirmationResult = props.onConfirmationResult.bind(this);
        this.onRecaptchaResponse = props.onRecaptchaResponse.bind(this);
        this.onSubmitOTP = props.onSubmitOTP.bind(this);
        this.onSignInSuccess = props.onSignInSuccess.bind(this);
        console.log("phone state", this.state.viewState)
        this.auth = getAuth(app)
        console.log('auth', this.auth)
        console.log('referral code', this.state.referralCode)
    }

    validatePhoneNumber = (phoneNumber) => {
        const phoneRegex = /^[0-9]{10}$/;
        return phoneRegex.test(phoneNumber);
    }

    handlePhoneNumberChange = (e) => {
        this.setState({ phoneNumber: e.target.value });
        
    }

    handleInputChange = (event) => {
        this.setState({ phoneNumber: event.target.value });
        this.setState({ isPhoneNumberValid: this.validatePhoneNumber(event.target.value) });
    };
    
    openModal = () => {
        this.setState({ isModalOpen: true });
    };
    

    closeModal = () => {
        this.setState({ isModalOpen: false });
    };
    
    selectCountry = (country) => {
        this.setState({ selectedCountry: country });
        this.closeModal();
    };
    

    handleSubmit = async () => {
        // Here you can handle the submission of the phone number
        this.setState({ viewState: 'loading' });
        console.log('Selected Country Code:', this.state.selectedCountry.code);
        console.log('Phone Number:', this.state.phoneNumber);
        const number = this.state.selectedCountry.code + this.state.phoneNumber;
        
        this.setUpRecaptha(number)
        const appVerifier = this.recaptchaVerifier;

        signInWithPhoneNumber(this.auth, number, appVerifier)
        .then((confirmationResult) => {
            // SMS sent. Prompt user to type the code from the message
            // Save confirmationResult to verify the code later
            this.setState({ confirmationResult: confirmationResult, viewState: 'otp' });
            this.onConfirmationResult(confirmationResult)
            console.log("here", confirmationResult);
        })
        .catch((error) => {
            // Error; SMS not sent
            console.error(error);
        });
        
    };

    setUpRecaptha() {
        // const auth = getAuth(app); // Make sure 'app' is your Firebase app instance
        console.log("auth", this.auth.settings)
        const recaptchaVerifier = new RecaptchaVerifier(
            this.auth,
            "recaptcha-container",
            {
                settings: {},
                size: 'invisible',
                callback: (response) => {
                    // reCAPTCHA solved, allow signInWithPhoneNumber to proceed
                        console.log('reCAPTCHA solved:', response);
                        this.onConfirmationResult(response);
                        this.setState({ viewState: 'otp' });
                }
            },
            
        );
        this.recaptchaVerifier = recaptchaVerifier;
        recaptchaVerifier.render();
        // return signInWithPhoneNumber(auth, number, recaptchaVerifier);
    }


    render() {
        const { selectedCountry, phoneNumber, isModalOpen, viewState, confirmationResult } = this.state;
        console.log(viewState)
        let content;
        if (viewState === 'input') {
            content = (
                <Box
                    maxW="sm"
                    mx="auto"
                    mt="10"
                    p="6"
                >
                    <VStack spacing="4" align="flex-start">
                        <Text fontSize="lg" fontWeight="bold" lineHeight="normal">Phone Number</Text>
                        <FormControl>
                        <FormLabel>Please enter your phone number</FormLabel>
                        <HStack>
                        <Menu>
                            
                            <MenuButton as={Button}  bg="#5F22D9" color="white" px="5" padding={4}>
                                <CountryFlag
                                countryCode={selectedCountry.flagCode}
                                svg
                                style={{
                                    width: '1em',
                                    height: '2em',
                                    marginRight: '4px',
                                }}
                                />
                                {selectedCountry.code}
                            </MenuButton>
                            <MenuList>
                                {countryCodes.map((country) => (
                                <MenuItem key={country.code} onClick={() => this.selectCountry(country)}>
                                    <CountryFlag
                                    countryCode={country.flagCode}
                                    svg
                                    style={{
                                        width: '2em',
                                        height: '2em',
                                        marginRight: '8px',
                                    }}
                                    />
                      z              {country.name} ({country.code})
                                </MenuItem>
                                ))}
                            </MenuList>
                        </Menu>
                            <Input placeholder="Phone Number"  onChange={this.handleInputChange}/>
                        </HStack>
                        </FormControl>
                        <Button bg="#5F22D9" color="white" borderRadius="8px" onClick={() => this.handleSubmit()} height="48px" width="100%" >
                        Continue
                        </Button>
                        
                    </VStack>
                </Box>
            );
        } else { // Assuming 'otp' and the else branch have the same rendering
            content = (
                <div>
                    <EnterOTP confirmationResult={confirmationResult} onSubmitOTP={this.onSubmitOTP}/>
                </div>
                
            );
        }
    
        return (
            // 
                content            
        );
    }
    
}

export default EnterPhone;